<template>
  <LayoutFeed
    v-bind="{ getFirstPage, getNextPage }"
    :groq-params="groqParams"
    @load-more="onLoadMore"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #items="{ items, itemClass }">
      <OpportunitiesFeedItem
        v-for="post in items"
        :key="post._id"
        :post="post"
        :class="itemClass"
      />
    </template>
    <template #not-found>
      <slot name="not-found" />
    </template>
  </LayoutFeed>
</template>

<script setup lang="ts">
import type * as Schema from "~/@types/schema"

const props = withDefaults(
  defineProps<{
    getFirstPage: () => string
    getNextPage: () => string
    groqParams?: Record<string, unknown>
  }>(),
  {
    groqParams: () => ({}),
  },
)

const { groqParams } = toRefs(props)

const onLoadMore = (item: Schema.Opportunities) => {
  const { _id, publishedAt } = item

  groqParams.value.lastId = _id
  groqParams.value.lastPublishedAt = publishedAt
}
</script>
